//'use client';
import { CardMarketEntityMobile } from '@entities/mobile/cards';

import { mockedCategoriesMobile1 } from '@/mocks/market-categories';

import styles from './categories.module.scss';

export const CategoriesWidgetMobile = () => {
  const categories = mockedCategoriesMobile1;

  return (
    <div className={styles['content-widget__categories']}>
      {categories?.map(({ id, ...category }, idx) => {
        return (
          <CardMarketEntityMobile
            key={`mobile-${idx}-${category.title}-${category.item}`}
            {...category}
          />
        );
      })}
    </div>
  );
};
